<template>

  <div class="core-option-list-item p-2">
    <h3 class="title is-3">{{ core_option.option.core_option }}</h3>
    <p v-if="core_option.previous_marks.length===0">
      No previous modules entered
    </p>

    <template v-else>
      <p class="is-size-5" v-if="(total_credits<core_option.option.credit)&&(core_option.option.provide_supplementary_modules===1)&&(core_option.option.requires_credits===1)">
        You do not have enough credits for this section, but this is no reason to be concerned, as modules will be added to your curriculum to make up for this.
      </p>
      <b-field v-if="core_option.option.requires_credits===1" label="Credits Required">
        <b-input disabled class="un-input" :value="core_option.option.credit" ></b-input>
      </b-field>
      <ValidationProvider v-slot="{ errors }"
                          :rules="(core_option.option.requires_credits===1)&&(core_option.option.provide_supplementary_modules===0)?`min_value:${core_option.option.credit}`:''"
                          :skipIfEmpty="false">
        <b-field
          :message="errors.length>0?errors[0]:''"
          :type="errors.length>0?'is-danger':''"
          label="Total Credits Entered"
        >
          <b-input :value="total_credits" disabled type="number"></b-input>
        </b-field>
      </ValidationProvider>
      <template v-for="(module,index) in core_option.previous_marks">
        <b-field :key="index" group-multiline grouped>

          <b-field expanded label="Institution Name">
            <b-input v-model.trim="module.institution" :disabled="!canEdit" expanded placeholder="Institution Name" required></b-input>
          </b-field>

          <ValidationProvider v-if="core_option.option.allow_non_listed_prior_modules ===1" v-slot="{ errors }" :rules="`required|blacklist:${blacklist_string}`"
                              :skipIfEmpty="false"
                              class="control is-expanded">
            <b-field :message="errors.length>0?errors[0]:''"
                     :type="errors.length>0?'is-danger':''"
                     expanded
                     label="Module Code"
            >
              <b-autocomplete v-model.trim="module.module_code" :data="filtered_whitelist"
                              :disabled="!canEdit"
                              expanded
                              placeholder="Module Code"
                              @select="option => module.module_code = option"
                              @typing="filterWhiteList">
              </b-autocomplete>
            </b-field>
          </ValidationProvider>
          <b-field v-else expanded label="Module Code">
            <b-select v-model="module.module_code" :disabled="!canEdit" expanded placeholder="Module Code" required>
              <option v-for="(item,index) in core_option.option.whitelisted_prior_modules" :key="index" :value="item">
                {{ item }}
              </option>
            </b-select>
          </b-field>

          <b-field expanded label="Module Mark (%)">
            <b-input v-model="module.grade" :disabled="!canEdit" :min="0" expanded placeholder="Module Mark (%)" required type="number"></b-input>
          </b-field>

          <b-field expanded label="Credits">
            <b-input v-model.number="module.credits" :disabled="!canEdit" :min="0" expanded placeholder="Credits" required type="number"></b-input>
          </b-field>
          <ValidationProvider v-slot="{ errors }" :rules="core_option.option.requires_nqf_level===1?`min_value_nqf:${core_option.option.minimum_nqf_level}`:''"
                              :skipIfEmpty="false"
                              class="control is-expanded">
            <b-field
              :message="errors.length>0?errors[0]:''"
              :type="errors.length>0?'is-danger':''"
              label="NQF Level"
              expanded
            >
              <b-select expanded v-model="module.nqf_level" :disabled="!canEdit" :min="0" placeholder="NQF Level" required  >
                <option v-for="i in 6" :key="i" :value="i+4">{{i+4}}</option>
              </b-select>
              </b-field>
          </ValidationProvider>

          <b-field expanded label="Semester"
          >
            <b-select v-model="module.semester" :disabled="!canEdit" :min="0" expanded placeholder="Semester" required >
              <option :value="1">1</option>
              <option :value="2">2</option>
              <option :value="0">Full Year</option>
            </b-select>
          </b-field>
          <b-field label="Remove">
            <b-button :disabled="!canEdit" :icon-right="$tc('icons.delete')" expanded type="is-danger" @click="remove_module(index)"></b-button>
          </b-field>

        </b-field>
        <hr :key="index + 'hr'" class="mx-0">
      </template>
    </template>

    <b-field>
      <b-button
        type="is-success"
        :disabled="!canEdit"
        @click.prevent="add_module"
      >Add module
      </b-button>
    </b-field>
  </div>

</template>

<script>

import {extend, ValidationProvider} from "vee-validate";
import {min_value, required} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("min_value", {
  ...min_value,
  message: "Insufficient Credits",
});
extend("min_value_nqf", {
  ...min_value,
  message: "NQF level is too low",
});

extend('blacklist', {
  validate(value, args) {
    const text = value.toString()
      .toLowerCase().trim();

    if (args.blacklist.includes(text)) {
      return "This option is not accepted"
    } else {
      return true
    }
  },
  params: ['blacklist']
});

export default {
  name: "CoreOptionListItem",
  components: {
    ValidationProvider
  },
  data() {
    return {
      filtered_whitelist: []
    }
  },

  methods: {
    remove_module(index) {
      this.$buefy.dialog.confirm({
        title: "Deleting previous module",
        message:
          "Are you sure you want to <b>delete</b> this? This action cannot be undone.",
        confirmText: "Delete Module",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          (this.core_option.previous_marks = this.core_option.previous_marks.filter(
            (item, place) => place !== index
          )),
      });
    },
    add_module() {
      this.core_option.previous_marks.push({
        institution: null,
        module_code: null,
        grade: null,
        credits: null,
        semester: null
      })
    },
    filterWhiteList(text) {
      this.filtered_whitelist = this.core_option.option.whitelisted_prior_modules.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    }
  },

  computed: {
    total_credits() {
      let sum = 0
      this.core_option.previous_marks.map(item => {
        sum += item.credits !== null ? item.credits : 0
      })
      return sum
    },
    blacklist_string() {
      return this.core_option.option.blacklisted_prior_modules.map(item => item.toString()
        .toLowerCase()).toString()

    },
  },

  props: {


    core_option: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      default() {
        return false
      }
    }, canEdit: {
      type: Boolean,
      default() {
        return false
      }
    },

  }
}
</script>

